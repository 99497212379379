import { Author } from 'models/Author';

type RequiredInput = GatsbyTypes.AuthorBasicFragment & {
  [key:string]: any,
};

export default (
  queryData?: RequiredInput,
): Author | null => {
  if (!queryData) return null;
  return new Author(
    {
      height: queryData.avatar?.height || 0,
      width: queryData.avatar?.width || 0,
      url: queryData.avatar?.url || '',
    },
    queryData.description,
    queryData.firstName,
    queryData.lastName,
    queryData.name,
  );
};
