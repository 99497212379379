import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';

export interface Props {
  authorName: string,
  bio?: string,
}

const AuthorInfo:React.FC<Props> = ({
  authorName,
  bio,
}) => {
  return (
    <AuthorInfoOuter>
      <Typography variant="small">Written by</Typography>
      <Typography variant="small" bold>{authorName}</Typography>
      {bio && (
        <Spacer mt={1}>
          <Typography variant="small">{bio}</Typography>
        </Spacer>
      )}
    </AuthorInfoOuter>
  );
};
export default AuthorInfo;

const AuthorInfoOuter = styled.div``;
