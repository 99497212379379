import React from 'react';
import { BlogPosting } from 'schema-dts';
import SchemaBase from 'lib/components/schemas/SchemaBase';
import { Post } from 'src/models/Post';
import useSiteSettings from 'src/hooks/queries/useSiteSettings';
import useGeneralSettings from 'src/hooks/queries/useGeneralSettings';


interface Props {
  post: Post,
}


const BlogPostSchema:React.FC<Props> = ({ post }) => {
  const siteSettings = useSiteSettings();
  const generalSettings = useGeneralSettings();
  const schema: BlogPosting = {
    '@type': 'BlogPosting',
    headline: post.title,
    image: (
      post.featuredImage && `${generalSettings.url || ''}/${post.featuredImage?.images.fallback?.src || ''}`
    ),
    datePublished: post.date,
    author: {
      '@type': 'Person',
      name: post.author.name,
    },
    publisher: {
      '@type': 'Corporation',
      name: siteSettings.businessName,
      logo: {
        '@type': 'ImageObject',
        url: siteSettings.businessLogo?.localFile?.childImageSharp?.fixed?.src,
      },
    },
  };
  return <SchemaBase schema={schema} />;
};
export default BlogPostSchema;
