import React from 'react';
import { Author } from 'src/models/Author';
import styled from 'styled-components';
import InfoPost from './InfoPost';
import AuthorAvatar from './AuthorAvatar';
import InfoAuthor from './InfoAuthor';

interface PropsCommon {
  author: Author,
}

type PropsAuthorInfo = PropsCommon & {
  type: 'authorInfo',
  postDate?: never,
  postReadingTime?: never,
};

type PropsArticleInfo = PropsCommon & {
  type: 'postInfo',
  postDate: string,
  postReadingTime?: number,
};

export type Props = PropsAuthorInfo | PropsArticleInfo;


const AuthorInfoBlock:React.FC<Props> = ({
  author,
  type = 'postInfo',
  postDate,
  postReadingTime,
}) => {
  const authorName = author.name;
  return (
    <AuthorInfoBlockOuter>
      <AvatarWrap>
        <AuthorAvatar
          avatar={author.avatar}
          title={authorName}
        />
      </AvatarWrap>
      <InfoWrap>
        {type === 'postInfo' ? (
          <InfoPost
            authorName={authorName}
            date={postDate || ''} // we shouldn't need this "OR"...
            postReadingTime={postReadingTime}
          />
        ) : (
          <InfoAuthor
            authorName={authorName}
            bio={author.biography}
          />
        )}
      </InfoWrap>
    </AuthorInfoBlockOuter>
  );
};
export default AuthorInfoBlock;

const AuthorInfoBlockOuter = styled.div`
  display: flex;
  align-items: center;
`;
const AvatarWrap = styled.div`
  margin-right: 15px;
`;
const InfoWrap = styled.div``;
