import React from 'react';
import { graphql } from 'gatsby';
import Container from 'src/components/Container';
import Seo from 'src/lib/components/Seo';
import PageHeader from 'src/components/PageHeader';
import PostQuery_Post from 'src/transformers/PostQuery_Post';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import { resolveUrl } from 'src/helpers';
import CtaBlock from 'src/components/CtaBlock';
import Block from 'src/components/Block';
import AuthorQuery_Author from 'src/transformers/AuthorQuery_Author';
import AuthorInfoBlock from 'src/components/AuthorInfoBlock';
import Spacer from 'src/components/Spacer';
import isNotNull from 'src/types/guards/isNotNull';
import PostsWidget from 'src/components/PostsWidget/Component';
import BlogPostSchema from 'src/components/schemas/BlogPostSchema';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

interface Props {
  data: GatsbyTypes.PostSingleQuery,
}

export const query = graphql`
  query PostSingle($postId: String) {
    wpPost(id: {eq: $postId}) {
      ...PostWithReadingTime
      ...PostWithThumbnail
      acfSeo {
        metaDescription
      }
    }
    latestPosts: allWpPost(
      filter: { id: { ne: $postId } },
      sort: { fields: date, order: DESC },
      limit: 3,
    ) {
      nodes {
        ...PostWithThumbnail
      }
    }
  }
`;

const PostSinglePage: React.FC<Props> = ({ data }) => {
  const pageData = BasicQuery_SitePage(data.wpPost || {});
  const post = PostQuery_Post(data.wpPost);
  if (post === null) return null;

  const author = AuthorQuery_Author(data.wpPost?.author?.node);
  const latestPosts = Array.from(data.latestPosts?.nodes, PostQuery_Post).filter(isNotNull);

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Blog', url: resolveUrl.post('') },
    { label: pageData.navTitle },
  ];

  return (
    <>
      <BlogPostSchema post={post} />
      <Seo
        title={pageData.metaTitle}
        // titlePath="Blog"
        description={pageData.metaDescription}
      />
      <PageHeader
        category="Blog"
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Container
          width="40em"
          marginAuto={false}
          xPadding={0}
          yPadding={0}
        >
          {author && (
            <Spacer py={3}>
              <AuthorInfoBlock
                type="postInfo"
                author={author}
                postDate={post.date}
                postReadingTime={post.readingTime}
              />
            </Spacer>
          )}

          {pageData.contentHtml && (
            <Block type="noPadding">
              <ContentParser>{pageData.contentHtml}</ContentParser>
            </Block>
          )}

          {author && (
            <Spacer py={3}>
              <AuthorInfoBlock
                type="authorInfo"
                author={author}
              />
            </Spacer>
          )}
        </Container>
      </Container>

      {latestPosts.length > 0 && (
        <Block darkBg>
          <Container yPadding={0}>
            <PostsWidget tiles={latestPosts} title="Latest posts." />
          </Container>
        </Block>
      )}

      <CtaBlock />
    </>
  );
};

export default PostSinglePage;
