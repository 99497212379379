import React from 'react';
import { Author } from 'src/models/Author';
import styled from 'styled-components';

export interface Props {
  avatar: Author['avatar'],
  title?: string,
}

const AuthorAvatar:React.FC<Props> = ({ avatar, title }) => {
  return (
    <AuthorAvatarOuter
      imgUrl={avatar.url}
      title={title}
      $width={avatar.width}
      $height={avatar.height}
    />
  );
};
export default AuthorAvatar;

const AuthorAvatarOuter = styled.div<{ imgUrl: string, $width: number, $height: number }>`
  width: ${(p) => p.$width / 2}px;
  height: ${(p) => p.$height / 2}px;
  border-radius: 50%;
  background-image: url(${(p) => p.imgUrl});
  background-size: cover;
  background-repeat: no-repeat;
`;
