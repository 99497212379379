import React from 'react';
import styled from 'styled-components';
import { format } from 'helpers/date';
import Typography from 'components/Typography';

export interface Props {
  authorName: string,
  date: string,
  postReadingTime?: number,
}

const ArticleInfo:React.FC<Props> = ({
  authorName,
  date,
  postReadingTime,
}) => {
  const formattedDate = format(date);
  return (
    <ArticleInfoOuter>
      <Typography variant="small" bold>{authorName}</Typography>
      <Typography variant="small">
        <Span>{formattedDate}</Span>
        {postReadingTime && (
          <Span>{postReadingTime} min read</Span>
        )}
      </Typography>
    </ArticleInfoOuter>
  );
};
export default ArticleInfo;

const ArticleInfoOuter = styled.div``;
const Span = styled.span`
  &:after {
    content: ' • ';
  }
  &:last-of-type:after {
    content: none;
  }
`;
